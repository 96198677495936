﻿(function ($) {
    $("html").addClass("custom-scroll");

    // mobile devices have a lot of issues with css position: fixed when focusing inputs
    if (typeof $.browser !== "undefined" && $.browser.mobile && $("html").hasClass("fixed")) {
        $("html").removeClass("fixed").addClass("scroll");
    }

    if (typeof $.browser !== "undefined" && $.browser.mobile) {
        $("html").addClass("mobile-device");
    } else {
        $("html").addClass("no-mobile-device");
    }
}).apply(this, [jQuery]);

(function (SnelStartCustomJS) {
    SnelStartCustomJS.registerEasterEgg = function (keyCodes, event, whenKeycodesMatchFunction) {
        var keyPresses = [];
        var eventHandler = (function (e) {
            keyPresses.push(e.keyCode);

            if (!keyPresses.toString().startsWith(keyCodes.split(",")[0]) || keyCodes.indexOf(keyPresses.toString()) === -1) {
                keyPresses = [];
            }

            if (keyPresses.toString().indexOf(keyCodes) === 0) {
                $(document).off(event, eventHandler);
                whenKeycodesMatchFunction();
            }
        });
        $(document).on(event, eventHandler);
    };
}(window.SnelStartCustomJS = window.SnelStartCustomJS || {}));

(function () {
    // Konami code
    SnelStartCustomJS.registerEasterEgg("38,38,40,40,37,39,37,39,66,65", "keydown", function () {
        $("body").addClass("barrel_roll");
        setTimeout(function () {
            $("body").removeClass("barrel_roll");
        }, 4000);
    });

    // #glaasiewater
    SnelStartCustomJS.registerEasterEgg("35,103,108,97,97,115,105,101,119,97,116,101,114", "keypress", function () {
        var html = document.createElement("h3");
        html.innerHTML = "(◑‿◐)";
        html.setAttribute("style", "margin: auto 33px");
        var header = document.querySelector("footer img");
        if (header) {
            header.replaceWith(html);
        }
    });

    // ctrllekkereddie
    SnelStartCustomJS.registerEasterEgg("17,76,69,75,75,69,82,69,68,68,73,69", "keyup", function () {
        const searchboxValue = document.getElementById("generalSearchBox").value;
        const png = "../assets/img/favicon42x42.png";
        if (searchboxValue === "lekkereddie") {
            let audio = new Audio();
            audio.src = png;
            audio.load();
            audio.play();
            delete audio;
        }
    });
})();