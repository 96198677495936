﻿function LoadTenClr(module)
{
    let url = "https://snelstart-test.10cls.io/js";
    
    if (module === "translator") {
        url += "/init.js?app=1&translator=on";
    } else if (module === "tack") {
        url += "/tack/init.js?app=1";
    } else {
        throw new "10cls.loader.js: No module specified.";
    }

    (function (document, src) {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.async = 1;
        script.src = src;
        head.appendChild(script);
    })(document, url);
}