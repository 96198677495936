// This function is a direct and simple conversion from the script obtained from Funnelytics.
// Due to the limitations of using script tags in Angular components it had to be done this way.
// The conversion works as follows:
// 1. The script is a function call. Strip the function call (.......)('funnelytics-funnel-guid');. The content of ....... remains.
// 2. The content ....... looks like an unnamed function: function(funnel) {...}. Name the function LoadFunnelytics.
// 3. Format the script using an online tool like https://js-formatter.com/ or using the IDE formatter.
// 4. Replace the script below this comments with the new version.
function LoadFunnelytics(funnel) {
    var deferredEvents = [];
    window.funnelytics = {
        events: {
            trigger: function (name, attributes, callback, opts) {
                deferredEvents.push({
                    name: name,
                    attributes: attributes,
                    callback: callback,
                    opts: opts
                });
            }
        }
    };
    var insert = document.getElementsByTagName('script')[0],
        script = document.createElement('script');
    script.addEventListener('load', function () {
        window.funnelytics.init(funnel, false, deferredEvents);
    });
    script.src = 'https://cdn.funnelytics.io/track.js';
    script.type = 'text/javascript';
    script.async = true;
    insert.parentNode.insertBefore(script, insert);
}